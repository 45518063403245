// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'staging',
  production: false,
  maintenanceMode: false,
  features: [], //'procore'
  url: 'https://staging.hidoefacilities.org',
  apiUrl: 'https://api.staging.hidoefacilities.org',
  cognitoDomain: 'cpt-userpool-staging.auth.us-west-2.amazoncognito.com',
  cognitoUserPoolId: 'us-west-2_24jmYbRCR',
  cognitoClientId: '2o74dictoh71qn99rhh0cq87nb',
  auth0TenantId: 'dev-58cds8tb4yeta8pm',
  auth0ClientId: 'TBfdPkWpd4SwpHzsaJ1fNc6v9ibnaW6l',
  procoreUrl: 'https://sandbox.procore.com/4063550/company/home/list',
  procoreAuthUrl: 'https://login-sandbox.procore.com/oauth',
  procoreApiUrl: 'https://sandbox.procore.com/rest/v1.0',
  procoreClientId: '',
  procoreClientSecret: '',
  cpt1Domain: '',
  cpt1Logout: '',
  ssoEnabled: true,
  clarityProjectId: 'myo9w1nu5t',
  dynatraceSrc: '',
  portalUrl: '',
  dwoUrl: 'https://hidoetest.datahouse.com/constructionMgmtDWO_Search.php',
  quicksightEmbedUrl:
    'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/283887665851/dashboards/ca328d3b-9416-413b-8e42-8f62a1425c94?directory_alias=dhc-data-analytics-quicksight',
  quicksightLoginUrl:
    'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/283887665851/transition?directory_alias=dhc-data-analytics-quicksight',
  doeFacilitiesPortal: 'https://hidoefacilities.org',
  cspUrl: 'https://staging.csp.hidoefacilities.org',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
